<template>
  <v-card>
    <v-card-title>取引先(編集)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cust_abbr">取引先略称<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="cust_abbr"
                v-model="$v.CustomerEditForm.cust_abbr.$model"
                outlined
                dense
                readonly
                :error-messages="idErrors"
                placeholder="取引先略称"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <!-- <v-col
              cols="12"
              md="1"
            >
            </v-col>
            <v-col
              cols="12"
              md="1"
            >
              <label for="supplier">仕入先</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-checkbox
                id="supplier"
                v-model="CustomerEditForm.supplier"
                hide-details
                outlined
                dense
              ></v-checkbox>
            </v-col> -->
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cust_name">取引先名<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="cust_name"
                v-model="$v.CustomerEditForm.cust_name.$model"
                outlined
                dense
                :error-messages="nameErrors"
                placeholder="取引先名（日本語、中国語等）"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cust_name">取引先名（ENG）<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="cust_name_eng"
                v-model="$v.CustomerEditForm.cust_name_eng.$model"
                outlined
                dense
                :error-messages="nameEngErrors"
                placeholder="取引先名(英語)"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="country_name">国・地域ID<span style="color:red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="CustomerEditForm.country_id"
                outlined
                dense
                hide-details="auto"
                :items="countryMaster"
                :error-messages="countryIdErrors"
                placeholder="国・地域ID"
              ></v-autocomplete>
              <!-- <form-group :validator="$v.CustomerEditForm.country">
                <country-select
                  id="country_name"
                  v-model="CustomerEditForm.country"
                  slot-scope="{ attrs }"
                  :error-messages="countryErrors"
                  placeholder="国・地域ID"
                  v-bind="attrs"
                  @input="$v.CustomerEditForm.country.$touch()"
                />
              </form-group> -->
            </v-col>
          </v-row>
          <!-- <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="region">都市ID</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <region-select
                v-model="CustomerEditForm.area"
                :country="CustomerEditForm.country == null ? CustomerEditForm.country : CustomerEditForm.country.countryName"
                placeholder="都市ID"
              />
            </v-col>
          </v-row> -->
          <!-- <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>国番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="CustomerEditForm.country_code"
                outlined
                dense
                readonly
                placeholder="国番号"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="postcode">郵便番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="postcode"
                v-model="CustomerEditForm.postcode"
                outlined
                dense
                placeholder="郵便番号"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="address1">住所1</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="address1"
                v-model="CustomerEditForm.address1"
                outlined
                dense
                placeholder="住所1（東京都台東区/中国湖北省武漢市）"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="address1_eng">Address1</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="address1_eng"
                v-model="CustomerEditForm.address1_eng"
                outlined
                dense
                placeholder="Address1"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="address2">住所2</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="address2"
                v-model="CustomerEditForm.address2"
                outlined
                dense
                placeholder="住所２（台東区上の１－３－１）"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="address2_eng">Address2</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="address2_eng"
                v-model="CustomerEditForm.address2_eng"
                outlined
                dense
                placeholder="Address2"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="corp_rep_title">法人格</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="corp_rep_title"
                v-model="CustomerEditForm.corp_rep_title"
                outlined
                dense
                placeholder="代表取締役/法人代表"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="corp_rep_name">法人代表者名</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="corp_rep_name"
                v-model="CustomerEditForm.corp_rep_name"
                outlined
                dense
                placeholder="小泉 太郎/王 大刚"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="manager_sei">連絡人姓名</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="manager_sei"
                v-model="CustomerEditForm.manager_sei"
                outlined
                dense
                placeholder="連絡人姓（佐藤・劉）"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="manager_mei"
                v-model="CustomerEditForm.manager_mei"
                outlined
                dense
                placeholder="連絡人名（一郎・大剛）"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="manager_sei">連絡人姓名（ENG）</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="manager_firstname"
                v-model="CustomerEditForm.manager_firstname"
                outlined
                dense
                placeholder="連絡人姓(ENG)"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="manager_lastname"
                v-model="CustomerEditForm.manager_lastname"
                outlined
                dense
                placeholder="連絡人名(ENG)"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="mobile">携帯電話番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="mobile"
                v-model="CustomerEditForm.mobile"
                outlined

                dense
                placeholder="携帯電話番号"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="tel">固定電話番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="tel"
                v-model="CustomerEditForm.tel"
                outlined
                dense
                placeholder="固定電話番号"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cust_tax_no">税番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="cust_tax_no"
                v-model="$v.CustomerEditForm.cust_tax_no.$model"
                outlined
                dense
                placeholder="税番号"
                :error-messages="taxNoErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="email">E-mail</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="email"
                v-model="CustomerEditForm.email"
                outlined
                :error-messages="emailErrors"
                dense
                placeholder="E-mail"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="fax">FAX</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="fax"
                v-model="CustomerEditForm.fax"
                outlined
                dense
                placeholder="FAX"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="website">Website</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="website"
                v-model="CustomerEditForm.website"
                outlined
                dense
                placeholder="Website"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="wechat">WECHAT</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="wechat"
                v-model="CustomerEditForm.wechat"
                outlined
                dense
                placeholder="WECHAT"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="line">LINE</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="line"
                v-model="CustomerEditForm.line"
                outlined
                dense
                placeholder="LINE"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="line">状態</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="CustomerEditForm.cust_stat"
                placeholder="状態"
                :items="custStatuses"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit(CustomerEditForm)"
              >
                Submit
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </template>
  </v-card>
</template>

<script>
import {
  helpers,
} from '@vuelidate/validators'
import {
  required,
  maxLength,
} from 'vuelidate/lib/validators'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

// import CountrySelect from '@/components/form/country-select.vue'

// import RegionSelect from '@/components/form/region-select.vue'

const allowedChar = helpers.regex(/^[0-9A-Z]*$/)

export default {
  components: {
    // CountrySelect,

    // RegionSelect,
  },
  data: () => ({
    submitStatus: false,
    CustomerEditForm: {
      country_id: '',

      // countryShortCode: '',
      country_code: '',

      cust_abbr: '',
      cust_name: '',
      cust_name_eng: '',

      postcode: '',
      address1: '',
      address1_eng: '',
      address2: '',
      adderss2_eng: '',
      corp_rep_title: '',
      corp_rep_name: '',

      manager_sei: '',
      manager_mei: '',
      manager_firstname: '',
      manager_lastname: '',
      mobile: '',
      cust_tax_no: '',
      email: '',
      tel: '',
      fax: '',
      website: '',
      line: '',
      wechat: '',

    },
    OriginalForm: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    countryList: [],
  }),

  validations: {
    CustomerEditForm: {
      cust_abbr: {
        required,
        allowedChar,
      },
      cust_name: {
        required,
      },
      cust_name_eng: {
        required,
      },

      // country_id: {
      //   required,
      // },
      country_id: {
        required,
      },
      cust_tax_no: {
        maxLength: maxLength(20),
      },

      // email: {
      //   required,
      //   email,
      // },
      // mobile: {
      //   required,
      //   numeric,
      // },
    },
  },

  computed: {
    ...mapState('countryStore', ['countryMaster', 'countryIds', 'countries', 'countryCode']),

    // ...mapState('areaStore', ['areaMaster', 'areaIds']),
    ...mapState('customerStore', ['selectedCustomer', 'custStatuses']),
    country_id() {
      if (this.countryMaster) {
        const countryKey = this.CustomerEditForm.country_id

        // console.log('CustomerEditForm.country_id', this.countryMaster[countryKey])

        return this.countryMaster[countryKey]
      }

      return ''
    },

    idErrors() {
      const errors = []
      if (!this.$v.CustomerEditForm.cust_abbr.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerEditForm.cust_abbr.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerEditForm.cust_abbr.allowedChar && errors.push('半角英大文字と半角数字を入力してください。')

      return errors
    },
    taxNoErrors() {
      const errors = []
      if (!this.$v.CustomerEditForm.cust_tax_no.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerEditForm.cust_tax_no.maxLength && errors.push('最大20文字までです！')

      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.CustomerEditForm.cust_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerEditForm.cust_name.required && errors.push('必須項目')

      return errors
    },
    nameEngErrors() {
      const errors = []
      if (!this.$v.CustomerEditForm.cust_name_eng.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerEditForm.cust_name_eng.required && errors.push('必須項目')

      return errors
    },
    countryIdErrors() {
      const errors = []
      if (!this.$v.CustomerEditForm.country_id.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerEditForm.country_id.required && errors.push('必須項目')

      return errors
    },
    moblieErrors() {
      const errors = []

      // if (!this.$v.CustomerEditForm.mobile.$dirty) return errors
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.CustomerEditForm.mobile.required && errors.push('必須項目')
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.CustomerEditForm.mobile.numeric && errors.push('必須数字')

      return errors
    },
    emailErrors() {
      const errors = []

      // if (!this.$v.CustomerEditForm.email.$dirty) return errors
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.CustomerEditForm.email.required && errors.push('必須項目')
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.CustomerEditForm.email.email && errors.push('正しくメールアドレスを入力してください。')

      return errors
    },
  },
  watch: {
    // 'CustomerEditForm.country_id': {
    //   handler(val) {
    //     this.CustomerEditForm.country_code = this.countryCode[val]
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.loadCountryIds()

    // this.loadAreaIds()
    this.loadData()
  },
  methods: {
    ...mapActions('customerStore', ['loadCustomer', 'editCustomer', 'loadCustomerStatus']),
    ...mapActions('countryStore', ['loadCountryIds', 'loadCountries']),

    // ...mapActions('areaStore', ['loadAreaIds']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCountryIds(),
        this.loadCustomerStatus(),

        // this.loadCountryIds(),
        this.loadCustomer(this.$route.params.id),
      ])
        .then(() => {
          console.log('customer:', this.selectedCustomer)

          // this.countryList = this.countries.map(c => {
          //   const obj = {
          //   }
          //   obj.text = `${c.country_name} (${c.country_code})`
          //   obj.value = c.country_id

          //   return obj
          // })
          this.CustomerEditForm = this.$deepCopy(this.selectedCustomer)
          this.OriginalForm = JSON.stringify(this.CustomerEditForm)
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.setOverlayStatus(false)
        })
    },
    cancelClick() {
      if (this.OriginalForm !== JSON.stringify(this.CustomerEditForm)) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    jumpToListPage() {
      this.$router.push({
        path: '/customer-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    submit(CustomerEditForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true

        this.editCustomer(CustomerEditForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')

        // window.scrollTo(0, 0)
      }
    },
  },
}
</script>
